<template>
	<div class="manage-credit-cards-form-partial row">
		<spinner v-if="isLoading || isFetchingRequiredApiData" />
		<template v-else>
			<div class="col-md-12">
				<div class="float-left">
					<img class="csv-icon" src="@/assets/images/creditcard.svg">
					<h5 class="pl-5">{{$t("forms.creditCard")}}</h5>
				</div>
				<small-button @click.native="attemptCreateCard" :loader-active="isCreating" class="float-right" :text="$t('forms.addCreditCard')" />
				<div class="clearfix"/>
			</div>
			<div class="col-12 pt-3">
				<div v-for="(card,index) in companyCards" :key="index" class="card-row" :class="{disabled: card.isViable === false}">
					<div class="toggle-wrapper">
						<input :disabled="card.isViable === false" type="radio" name="defaultCard" v-model="defaultCard" :value="card.id" @click="changeDefaultCard(card)" />
					</div>
					<div class="card-name">
						<p><template v-if="card.last4 !== '' && card.last4 !== null">XXXX-XXXX-XXXX-{{card.last4}}</template><template v-else>{{$t('terms.unknown')}}</template> <template v-if="defaultCard === card.id">({{$t('terms.default')}})</template></p>
					</div>
					<div class="card-actions float-right">
						<div class="delete float-right" @click="promptDeleteCard(card)"></div>
						<p class="float-right mr-3">{{formatCardStatus(card.status)}}</p>
						<div class="clearfix"/>
					</div>
					<div class="clearfix"/>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	import SmallButton from "@/components/buttons/SmallButton";
	import Spinner from "@/components/loaders/Spinner";
	import requiresApiRequest from "@/mixin/requiresApiRequest";
	import {mapState} from "vuex";
	import FieldInput from "@/components/inputs/FieldInput";
	import dataConversionMethods from "@/mixin/dataConversionMethods";
	export default {
		name: "ManageCreditCardsFormPartial",
		components: {FieldInput, Spinner, SmallButton},
		mixins: [requiresApiRequest,dataConversionMethods],
		data(){
			return {
				isLoading: false,
				defaultCard: '',
				isCreating:false,
			}
		},
		computed: {
			...mapState('concrete', {
				cards: 'cards',
				apiKey: 'apiKey'
			}),
			companyCards(){
				let cards = [];
				Object.keys(this.cards).forEach((id) => {
					if(this.cards[id].company === '/api/companies/'+this.companyId)
					{
						cards.push(this.cards[id]);
					}
				});
				return cards;
			}
		},
		methods: {
			attemptCreateCard(){
				if(this.isCreating === false)
				{
					this.isCreating = true;
					this.$concreteApi.post(this.apiKey,'/cards/new-authorized', {company: '/api/companies/'+this.companyId})
					.then(response => {
						window.open(response);
					})
					.catch(error => {
						console.log(error);
						this.fireGenericError();
					});
				}
			},
			promptDeleteCard(card){
				const text = (card.isDefaultCard) ? this.$t("alerts.cardDeleteDefault") : this.$t("alerts.cardDelete",{last4:card.last4}) ;
				
				this.$swal.fire({
					icon: 'question',
					title: this.$t("alerts.deleteGenericTitle"),
					text: text,
					showCancelButton: true,
					confirmButtonColor: "#DC3545",
					confirmButtonText: this.$t("alerts.deleteGenericConfirmBtnText"),
					cancelButtonText: this.$t("alerts.deleteGenericCancelBtnText"),
				}).then((result) => {
					if (result.isConfirmed)
					{
						this.isLoading = true;
						this.$store.dispatch('concrete/genericEntityRequest', {
							skipMutations: true,
							entity: {'id': card.id, '@type': 'Card'},
							method: 'delete'
						})
						.then(response =>
						{
							this.$store.commit('concrete/deleteCard',card.id);
							this.fetchCards();
						})
						.then(() => {
							this.isLoading = false
						},() => {
							this.isLoading = false
						})
					}
				});
			},
			changeDefaultCard(card){
				if(card.isViable === true)
				{
					this.$store.dispatch('concrete/genericEntityRequest',{skipMutations: true,entity: {'id': card.id,'@type':'Card'},payload: {isDefaultCard: true},method: 'put'});
				}
				
			},
			fetchCards(){
				let promises = [];
				promises.push(this.addRequiredApiRequest({type: 'generic',payload: {parameters: {'company_equals': this.companyId},entity: {'@type': 'Card'}}}))
				if(promises.length > 0)
				{
					Promise.all(promises)
					.catch(error =>
					{
						console.log(error);
					});
				}
			}
		},
		mounted()
		{
			this.fetchCards();
		},
		watch: {
			companyId() {
				this.fetchCards();
			},
			companyCards(){
				Object.keys(this.cards).forEach((id) => {
					if(this.cards[id].isDefaultCard === true)
					{
						this.defaultCard = Number(id);
					}
				});
			}
		},
		props: {
			companyId: {
				type: Number,
				required: true
			}
		}
		
	};
</script>

<style lang="scss" scoped>
	.csv-icon{
		top: -4px;
		position: absolute;
	}
	.card-row{
		width: 100%;
		&.disabled{
			.card-name{
				color: $lightGray;
			}
		}
		.card-actions{
			float: left;
			.delete{
				display: inline-block;
				width: 18px;
				height: 18px;
				background: $black;
				float: right;
				border-radius: 3px;
				margin: 1px 5px 0 0;
				cursor: pointer;
				position: relative;
				&:before, &:after{
					display: block;
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					width: 8px;
					height: 2px;
					background: $white;
					transform: translate(-50%,-50%) rotate(45deg);
				}
				&:after{
					transform: translate(-50%,-50%) rotate(135deg);
				}
			}
		}
		.toggle-wrapper{
			float: left;
			width: 30px;
		}
		.card-name{
			float: left;
		}
	}

	.card-row {
		padding: 5px;
		.card-row:nth-child(even) {
			background-color: #F2F2F2;
		}
	}

</style>
