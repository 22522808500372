<template>
	<field-select :value="value" :options="countryOptions" :error="error" :label="label" :name="name" :classes="classes" :disabled="disabled" @input="updateValue()" />
</template>

<script>
	import FieldSelect from "@/components/inputs/FieldSelect";
	export default {
		name: "FieldCountrySelect",
		components: {FieldSelect},
		computed: {
			countryOptions(){
				const countries = ['DK','DE','FR','EN','NO','SE'];
				let options = [];
				
				countries.forEach((key) => {
					options.push({
						value: key,
						text: this.$t('countries.'+key)
					})
				});
				
				options.sort((a,b) => {
					if ( a.text < b.text ){
						return -1;
					}
					if ( a.text > b.text ){
						return 1;
					}
					return 0;
				});
				return options;
			}
		},
		methods: {
			updateValue(){
				this.$emit('input',this.$refs.select.value);
			}
		},
		props: {
			value: String|Number,
			error: String,
			options: Array,
			label: String,
			name: String,
			classes: {
				default: 'col-md-6',
				type: String
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
	};
</script>

<style scoped>

</style>
