<template>
    <form-container>
		<div class="part-label col-12">{{$t("titles.companyDetails")}}</div>
        <field-input :label='$t("labels.company")' id="company" v-model="values.name" :error="errors.name" :disabled="disabled" />
        <field-input :label='$t("labels.vatNumber")' id="cvr" v-model="values.cvr" :error="errors.cvr" :disabled="disabled"  />
        <field-input :label='$t("labels.billingEmail")' id="billing-email" v-model="values.billingEmail" :error="errors.billingEmail" :disabled="disabled"  />
        <field-input :label='$t("labels.attPerson")'  id="att-person" v-model="values.billingName" :error="errors.billingName" :disabled="disabled"  />
        <field-input :label='$t("labels.billingAddress")'  id="billing-address" v-model="values.billingAddress" :error="errors.billingAddress" :disabled="disabled" />
        <field-input :label='$t("labels.postalZip")'  id="postal-zip" classes="col-4 col-md-2" v-model="values.billingPostal" :error="errors.billingPostal" :disabled="disabled"  />
        <field-input :label='$t("labels.city")'  id="city" classes="col-8 col-md-4" v-model="values.billingCity" :error="errors.billingCity" :disabled="disabled"  />
        <field-input :label='$t("labels.phone")'  id="Phone" v-model="values.phone" :error="errors.phone" :disabled="disabled"  />
        <field-country-select :label='$t("labels.country")'  id="Country" v-model="values.billingCountry" :error="errors.billingCountry" :disabled="disabled" />
	    <template v-if="!disabled">
	        <div class="part-label col-12">{{$t("forms.createAdminLogin")}}</div>
	        <field-input :label='$t("labels.name")' id="name" v-model="values.userName" :error="errors.userName" :disabled="disabled"  />
	        <field-input :label='$t("labels.email")' id="email" v-model="values.userEmail" :error="errors.userEmail" :disabled="disabled"  />
	        <field-input :label='$t("labels.password")' id="password" type="password" v-model="values.userPassword" :error="errors.userPassword" :disabled="disabled"  />
	        <field-input :label='$t("labels.confirmPassword")' id="confirm-password" type="password" v-model="values.userPassword2" :error="errors.userPassword2" :disabled="disabled"  />
	    </template>
		<!-- 	@deprecated Card system is deprecated since version 2
	    <template v-if="showCreditCardAddForm">
		    <div class="col-12 pt-3">
			    <div class="col-12 dashed-line "/>
		    </div>
			<div class="col-12 pt-3">
				<manage-credit-cards-form-partial :companyId="companyEntity.id"/>
		    </div>
	    </template>
	    -->
	    <template v-if="!disabled">
		    <div class="col-12 pt-5">
			    <field-checkbox v-model="hasAcceptedTerms" :error="termsError">
					{{$t('forms.acceptTermsAndConditions')}} <a :href="$t('links.termsAndConditionsURL')" target="_blank">{{$t('forms.termsAndConfitions')}}</a>
				</field-checkbox>
		    </div>
		    <div class="col-12 pt-4">
			    <button type="button" class="btn btn-success btn-lg mr-2" @click="attemptSubmit">
				    <template v-if="isLoading">
					    <spinner />
				    </template>
				    <template v-else>
					    {{$t("terms.createAccount")}}
				    </template>
			    </button>
			    <button type="button" class="btn btn-danger btn-lg">{{$t("terms.cancel")}}</button>
		    </div>
	    </template>
    </form-container>
</template>

<script>
    import FormContainer from "@/components/container/FormContainer";
    import FieldInput from "@/components/inputs/FieldInput";
    import EntitySelect from "@/components/inputs/EntitySelect";
    import StandardButton from "@/components/buttons/StandardButton";
    import Spinner from "@/components/loaders/Spinner";
    import FieldCheckbox from "@/components/inputs/FieldCheckbox";
    import {simpleEmailValidation, validateGenericInput} from "@/helperFunctions";
    import {mapState} from "vuex";
    import SmallButton from "@/components/buttons/SmallButton";
    import ManageCreditCardsFormPartial from "@/components/forms/ManageCreditCardsFormPartial";
    import FieldCountrySelect from "@/components/inputs/FieldCountrySelect";
    export default {
        name: "AddEditCompanyForm",
        components: {
	        FieldCountrySelect,
	        ManageCreditCardsFormPartial,
	        SmallButton, FieldCheckbox, Spinner, StandardButton, FormContainer, FieldInput, EntitySelect},
	    data(){
        	return {
        		showSuccess:false,
        		isLoading: false,
		        hasAcceptedTerms: false,
		        termsError: '',
        		validateMap: {
			        userEmail: {
			        	type: 'email',
				        required: true
			        },
			        userName: {
				        type: 'string',
				        required: true
			        },
			        userPassword: {
				        type: 'string',
				        required: true,
				        minLength: 6
			        },
			        userPassword2: {
				        type: 'string',
				        required: true,
				        minLength: 6
			        },
			        name: {
				        type: 'string',
				        required: true
			        },
			        cvr: {
				        type: 'string',
				        required: true
			        },
			        phone: {
				        type: 'string',
				        required: true
			        },
			        billingEmail: {
				        type: 'email',
				        required: true
			        },
			        billingName: {
				        type: 'string',
				        required: true
			        },
			        billingAddress: {
				        type: 'string',
				        required: true
			        },
			        billingPostal: {
				        type: 'string',
				        required: true
			        },
			        billingCity: {
				        type: 'string',
				        required: true
			        },
			        billingCountry: {
				        type: 'string',
				        required: true
			        }
		        },
        		values: {
			        userEmail: "",
			        userName: "",
			        userPassword: "",
			        userPassword2: "",
			        name: "",
			        cvr: "",
			        phone: "",
			        billingEmail: "",
			        billingName: "",
			        billingAddress: "",
			        billingPostal: "",
			        billingCity: "",
			        billingCountry: "DK"
		        },
		        errors: {
			        userEmail: "",
			        userName: "",
			        userPassword: "",
			        userPassword2: "",
			        name: "",
			        cvr: "",
			        phone: "",
			        billingEmail: "",
			        billingName: "",
			        billingAddress: "",
			        billingPostal: "",
			        billingCity: "",
			        billingCountry: ""
		        }
	        }
	    },
	    computed: {
		    ...mapState('concrete', {
			    companies: 'companies',
		    }),
            hasError(){
            	if(this.termsError !== '')
            		return true;

            	let error = false;
            	const keys = Object.keys(this.errors);
            	for(let i = 0; i < keys.length; i++)
	            {
	                if(this.errors[keys[i]] !== '')
	                {
		                error = true;
		                break;
	                }
	            }
            	return error;
            },
		    companyEntity(){
                return (typeof this.company !== 'undefined' && typeof this.companies[this.company] !== 'undefined') ? this.companies[this.company] : false ;
		    }
	    },
	    methods: {
		    attemptSubmit(){
		    	this.validateInputs();
		    	if(!this.hasError)
			    {
			    	this.isLoading = true;
			    	const payload = Object.assign({},this.values);
			    	payload.locale = this.$i18n.locale;
			    	this.$concreteApi.post(undefined,'/create-company',payload,true)
				    .then(response => {

				        if(typeof response.created !== 'undefined')
				        {
				        	if(response.created === true)
					        {
						        this.$swal.fire({
							        icon: 'success',
							        title: this.$t("terms.created"),
							        text: this.$t("alerts.companyWasCreatedDescription"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
						        }).then((result) => {
							        this.$emit('createdCompany');
						        });
					        }
				        	else
					        {
					        	if(typeof response.fieldErrors !== 'undefined')
						        {
						        	Object.keys(response.fieldErrors).forEach((field) => {
								        this.errors[field] = this.$t('errors.'+response.fieldErrors[field]);
							        });
							        this.$swal.fire({
								        icon: 'error',
								        title: this.$t("terms.error"),
								        text: this.$t("errors.errorsInFields"),
										confirmButtonText: this.$t("terms.ok"),
										confirmButtonColor: "#495760"
							        });
						        }
					        }
				        }
				        else
				        {
					        this.fireGenericError();
				        }
				    })
				    .catch(error => {
				        console.log(error);
				    })
				    .then(() => {
					    this.isLoading = false;
				    },() => {
					    this.isLoading = false;
				    })
			    }
		    },
		    validateInputs(){
		    	this.resetErrors();

		    	let hasError = false;
			    if(this.values.userPassword !== this.values.userPassword2)
			    {
				    this.errors.userPassword = this.$t('errors.passwordsMustMatch');
				    this.errors.userPassword2 = this.$t('errors.passwordsMustMatch');
				    hasError = true;
			    }

		    	Object.keys(this.validateMap).forEach((type) =>
			    {
			    	const error = validateGenericInput(this.validateMap[type],this.values[type].trim());
				    if(error !== false)
				    {
					    this.errors[type] = this.$t('errors.'+error);
					    hasError = true;
				    }
			    });

			    if(!hasError && this.hasAcceptedTerms === false)
				    this.termsError = this.$t('errors.mustAcceptTerms');
		    },

        	resetErrors(){
		    	this.termsError = '';
        		this.errors = {
			        userEmail: "",
			        userName: "",
			        userPassword: "",
			        userPassword2: "",
			        name: "",
			        cvr: "",
			        phone: "",
			        billingEmail: "",
			        billingName: "",
			        billingAddress: "",
			        billingPostal: "",
			        billingCity: "",
			        billingCountry: ""
		        }
	        },
		    updateLocalValues(){
		    	this.hasAcceptedTerms = false;
		    	this.resetErrors();
		    	this.resetValues();
		    	if(this.companyEntity !== false)
			    {
				    Object.keys(this.validateMap).forEach((type) =>
				    {
					    if(typeof this.companyEntity[type] !== 'undefined')
					    {
					    	this.values[type] = this.companyEntity[type];
					    }
				    });
			    }
		    },
		    resetValues(){
		    	this.values = {
				    userEmail: "",
				    userName: "",
				    userPassword: "",
				    userPassword2: "",
				    name: "",
				    cvr: "",
				    phone: "",
				    billingEmail: "",
				    billingName: "",
				    billingAddress: "",
				    billingPostal: "",
				    billingCity: "",
				    billingCountry: "DK"
			    }
		    }
	    },
	    mounted()
	    {
	    	this.updateLocalValues();
	    },
	    watch: {
            companyEntity(){
            	this.updateLocalValues();
            }
	    },
	    props: {
        	disabled: {
        		type: Boolean,
		        default: false
	        },
		    company: Number|String,
		    showCreditCardAddForm: {
        		type: Boolean,
			    default: false
		    }
	    }
    }
</script>

<style lang="scss" scoped>
    .csv-icon{
        top: 10px;
        position: absolute;
    }
	.dashed-line{
		border-bottom: 2px dashed #979797;
	}
</style>
