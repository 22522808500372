<template>
	<div class="create-company pt-4">
		<template v-if="!showSuccess">
			<h4>{{$t("forms.billingDetails")}}</h4>
			<add-edit-company-form @createdCompany="showSuccess = true" />
		</template>
		<template v-else>
			<div class="box px-4 py-4 mt-1">
				<div class="row justify-content-center">
					<div class="col-lg-4 text-center">
						<p class="success-message pt-4">{{$t("createCompanyView.thanksPleaseVerify")}}</p>
						<router-link :to="{name:'login',params: {lang: $i18n.locale}}"><div class="btn btn-secondary">{{$t('terms.login')}}</div></router-link>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	import AddEditCompanyForm from "@/components/forms/AddEditCompanyForm";
	export default {
		name: "CreateCompany",
		components: {AddEditCompanyForm},
		data(){
			return {
				showSuccess: false
			}
		}
	};
</script>

<style lang="scss" scoped>

</style>
