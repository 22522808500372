import companyTypes from "@/companyTypes";

export default {
	methods: {
		builtAssignmentDetailsObject(assignment,direction){
			const typeDisplayMap = {
				from: this.$t('terms.pickup'),
				to: this.$t('terms.deliveryLatest')
			};
			const typeDisplay = typeof typeDisplayMap[direction] !== 'undefined' ? typeDisplayMap[direction] : false;
			const timeDisplay = direction === 'from' ? assignment.fromTime : assignment.toTime;
			const addressDisplay = direction === 'from' ? assignment.fromAddress : assignment.toAddress;
			let object = {
				machine: assignment.machine.name,
			};
			
			if(assignment.type === 1 || (direction === 'from' && assignment.type === 2 || direction === 'to' && assignment.type === 0))
			{
				const segment = direction === 'from' ? assignment.fromBookingSegment : assignment.toBookingSegment;
				object.openingHours = this.formatSiteOpenString(segment.booking);
				object.siteInfo = segment.booking.siteAdditionalInformation;
				object.date = this.$moment(timeDisplay).format("DD/MM-YYYY");
				object.assignment = typeDisplay+': '+this.$t('sentences.timeAt',{time:this.$moment(timeDisplay).format("HH:mm")})+'<br>'+addressDisplay;
				object.pilot = {
					name: segment.pilot.name,
					mobile: segment.pilot.mobile
				}
			}
			else
			{
				object.assignment = typeDisplay+': '+addressDisplay;
			}
			return object;
		},
		formatCardStatus(status){
			const formats = [
				this.$t('terms.unauthorized'),
				this.$t('terms.authorized'),
				this.$t('terms.expired'),
				this.$t('terms.cancelled')
			];
			return (typeof formats[status] !== 'undefined') ? formats[status] : status ;
		},
		formatAssignmentStatus(status){
			const formats = [
				this.$t('terms.pending'),
				this.$t('terms.accepted'),
				this.$t('terms.active'),
				this.$t('terms.completed'),
				this.$t('terms.cancelled')
			];
			return (typeof formats[status] !== 'undefined') ? formats[status] : status ;
		},
		formatSiteOpenString(booking){
			if(booking.siteAlwaysOpen)
				return this.$t('sentences.siteAlwaysOpen')
			
			return booking.siteOpen + ' - ' + booking.siteClose;
		},
		formatSegmentTime(segment){
			return this.$moment(segment.date).format("DD/MM/YYYY")+' '+(segment.startHour < 10 ? '0'+segment.startHour : segment.startHour)+'-'+(segment.endHour < 10 ? '0'+segment.endHour : segment.endHour);
		},
		formatSegmentPilot(segment){
			return segment.pilot !== null ? segment.pilot.name : this.$t('errors.notFulfilled');
		},
		formatBookingStatus(status){
			const formats = [
				this.$t('terms.pending'),
				this.$t('terms.ready'),
				this.$t('terms.completed'),
				this.$t('terms.cancelled')
			];
			return (typeof formats[status] !== 'undefined') ? formats[status] : status ;
		},
		formatBookingSegmentStatus(status){
			const formats = [
				this.$t('terms.expired'),
				this.$t('terms.blocked'),
				this.$t('terms.available'),
				this.$t('terms.pending'),
				this.$t('terms.ready'),
				this.$t('terms.active'),
				this.$t('terms.completed'),
				this.$t('terms.cancelled')
			];
			return (typeof formats[status] !== 'undefined') ? formats[status] : status ;
		},
		formatInvoiceStatus(status, type){
			const formats = [
				this.$t('terms.pending'),
				this.$t('terms.waiting'),
				this.$t('terms.paid'),
				this.$t('terms.rejected'),
				this.$t('terms.expired')
			];
			
			if(typeof type !== 'undefined' && type === 'refund')
			{
				formats[2] = this.$t('terms.refunded');
			}
			
			return (typeof formats[status] !== 'undefined') ? formats[status] : status ;
		},
		formatInvoiceType(type){
			const formats = {
				refund: this.$t('terms.creditnota'),
				payment: this.$tc('terms.invoice',1),
			};
			return (typeof formats[type] !== 'undefined') ? formats[type] : type ;
		},
		formatMachineStatus(status){
			const formats = [
				this.$t('terms.inactive'),
				this.$t('terms.active'),
			];
			
			return (typeof formats[status] !== 'undefined') ? formats[status] : status ;
		},
		formatCompanyType(type){
			const types = [
				this.$t(companyTypes.COMPANY.text),
				this.$t(companyTypes.PARTNER.text),
			];

			return (typeof types[type] !== 'undefined') ? types[type] : type ;
		},
		formatUserStatus(status){
			const formats = [
				this.$t('terms.inactive'),
				this.$t('terms.active'),
				this.$t('terms.blocked'),
			];
			
			return (typeof formats[status] !== 'undefined') ? formats[status] : status ;
		},
		formatHour(hour){
			return hour < 10 ? '0'+hour : hour;
		},
		formatBookingAllowed(state){
			return state
				? this.$t('terms.yes')
				: this.$t('terms.no');
 		}
	}
}
